export const UMF_ADMIN_TOKEN = 'umf-admin-token';
import _ from 'lodash';
// permissions
export const permissions = {
  PERMISSION_CREATE_FORM: {
    name: 'create_form',
    description:
      'allows users to create forms that are used in the mobile application',
  },
  PERMISSION_VIEW_BUSINESSES: {
    name: 'view_businesses',
    description: 'allow users to see businesses of the different borrowers',
  },
  PERMISSION_VIEW_LOAN: {
    name: 'view_loan',
    description: 'allows users to view existing loans',
  },
  PERMISSION_CREATE_LENDER: {
    name: 'create_lender',
    description: 'allows user to add more credit officers to this site',
  },
  PERMISSION_CREATE_ROLE: {
    name: 'create_role',
    description: 'allows users to create new roles with different permissions',
  },
  PERMISSION_DELETE_ROLE: {
    name: 'delete_role',
    description: 'allows user to delete a role',
  },
  PERMISSION_VIEW_ROLES: {
    name: 'view_roles',
    description: 'allows user to view the available roles',
  },
  PERMISSION_VIEW_LENDERS: {
    name: 'view_lenders',
    description:
      'allows users to view the current available credit officers with access to this site',
  },
  PERMISSION_CREATE_LOAN_APPLICATION_COMMENT: {
    name: 'create_loan_application_comment',
    description: 'User can add a comment to a loan application',
  },
  PERMISSION_CREATE_LOAN_APPLICATION_CONTACT_ATTEMPT: {
    name: 'create_loan_application_contact_attempt',
    description: 'User can add contact attempt to the loan application',
  },
  PERMISSION_LENDER_CAN_UPDATE_PHOTO: {
    name: 'lender_can_update_photo',
    description: 'User can update the photo status of an applicant',
  },
  PERMISSION_VIEW_LOAN_APPLICATIONS: {
    name: 'view_loan_applications',
    description: 'User can view loan applications',
  },
  PERMISSION_UPDATE_LOAN_APPLICATION_STAGE: {
    name: 'update_loan_application_status',
    description: 'The user can update the loan application status',
  },
  PERMISSION_UPDATE_ASK_PHOTO_RESUBMISSION: {
    name: 'ask_photo_resubmission',
    description: 'The user can ask for photo resubmission',
  },
  PERMISSION_UPDATE_LOAN_APPLICATION_CATEGORY: {
    name: 'update_loan_application_category',
    description: `User can ${'update_loan_application_category'.replaceAll(
      '_',
      ' ',
    )}`,
  },
  PERMISSION_UPDATE_LOAN_QUOTE: {
    name: 'update_loan_quote_permission',
    description: `User can ${'update_loan_quote_permission'.replaceAll(
      '_',
      ' ',
    )}`,
  },
  PERMISSION_CREATE_VISIT_ATTEMPT: {
    name: 'create_visit_attempt',
    description: `User can ${'create_visit_attempt'.replaceAll('_', ' ')}`,
  },
  PERMISSION_DISBURSE_LOANS: {
    name: 'disburse_loans',
    description: `User can ${'disburse_loans'.replaceAll('_', ' ')}`,
  },
  PERMISSION_VIEW_DASHBOARD: {
    name: 'view_dashboard',
    description: `User can ${'view_dashboard'.replaceAll('_', ' ')}`,
  },
  PERMISSION_UPDATE_BORROWER_DATA: {
    name: 'update_borrower_data',
    description: `User can ${'update_borrower_data'.replaceAll('_', ' ')}`,
  },
  PERMISSION_CREATE_PORTFOLIO: {
    name: 'create_and_update_portfolio',
    description: `User can ${'create_and_update_portfolio'.replaceAll(
      '_',
      ' ',
    )}`,
  },
  PERMISSION_UPDATE_BUSINESS: {
    name: 'update_business',
    description: `User can ${'update_business'.replaceAll('_', ' ')}`,
  },
  PERMISSION_ADD_MANUAL_PAYMENT: {
    name: 'can_add_manual_payment',
    description: `User can ${'can_add_manual_payment'.replaceAll('_', ' ')}`,
  },
};

export const SORT_QUERY_BY = {
  CREATED_AT_UTC_DESC: 'CREATED_AT_UTC_DESC',
  CREATED_AT_UTC_ASC: 'CREATED_AT_UTC_ASC',
};

export const DEFAULT_CURRENCY = 'UGX';

export const PHOTO_STATES = {
  GOOD: 'GOOD',
  BAD: 'BAD',
  UNKNOWN: 'UNKNOWN',
};

export type PHOTO_STATES_TYPE = 'GOOD' | 'BAD' | 'UNKNOWN';

export const VISIT_ATTEMPTS_OPTIONS = {
  UNKNOWN: 'UNKNOWN',
  CONFIRMED: 'CONFIRMED',
  NOT_CONFIRMED: 'NOT CONFIRMED',
};

export type VISIT_ATTEMPTS_OPTIONS_TYPE =
  | 'UNKNOWN'
  | 'CONFIRMED'
  | 'NOT CONFIRMED';

export const DISMISSAL_REASONS = [
  'Inaccurate  Details',
  'Has pending loan(s)',
  'Not Eligible',
  'Low income',
];

export const RATING_NUMBERS = ['1', '2', '3', '4', '5'];

export const PURPOSE_OF_LOAN_CATEGORY = ['Payments'];

export const ITEMS_PER_PAGE = 10;

export const PURPOSE_OF_LOAN = ['Pay workers', 'Pay Rent', 'Buy stock'];

export type STAGES_TYPE =
  | 'PRE_SCREENING'
  | 'SUBMITTED'
  | 'DUE_DILIGENCE'
  | 'FINAL_REVIEW'
  | 'DECLINED'
  | 'APPROVED'
  | 'RESUBMITTING'
  | 'RESUBMITTED'
  | 'DISBURSED'
  | 'SUBMITTED_FOR_DISMISSAL'
  | 'DISBURSEMENT_FAILED'
  | 'DISBURSEMENT_FAILED';

export type LOAN_STATUES_TYPE =
  | 'DISBURSED'
  | 'WRITTEN_OFF'
  | 'PENDING'
  | 'PAIN_IN_FULL'
  | 'CURRENT'
  | 'IN_ARREARS';
