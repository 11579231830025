import React, {useEffect, useState} from 'react';

function useMessage() {
  const [message, setMessage] = useState<any>({});

  useEffect(() => {
    if (!('EventSource' in window)) {
      return;
    }
    const url = `${process.env.GATSBY_BASE_URL}/stream`;
    const sse = new EventSource(url, {
      withCredentials: true,
    });

    sse.addEventListener('payments', env => {
      setMessage({...message, payments: JSON.parse(env?.data ?? '{}')});
    });

    sse.onmessage = msg => {
      setMessage({...message, message: JSON.parse(msg?.data ?? '{}')});
    };

    sse.onerror = e => {
      console.error(e);
      sse.close();
    };
    sse.onopen = msg => {};

    return () => {
      sse.close();
    };
  }, []);

  return {message};
}

type SSEMessageType = {
  message: any;
};

type SSEProps = {
  children: React.ReactNode;
};

const SSEContext = React.createContext<SSEMessageType>({
  message: '',
});

export const SSEProvider = ({children}: SSEProps) => {
  const message = useMessage();
  return <SSEContext.Provider value={message}>{children}</SSEContext.Provider>;
};

export const useSSE = () => React.useContext<SSEMessageType>(SSEContext);
