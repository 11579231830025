/* eslint-disable react/display-name */
import React from 'react';
import {ApolloProvider} from '@apollo/client';
import client from './utils/apollo-client';
import {SSEProvider} from './hooks/useServerSideMesages';

interface ElementType {
  element: JSX.Element | JSX.Element[];
}

export default function ({element}: ElementType) {
  return (
    <ApolloProvider client={client}>
      <SSEProvider>{element}</SSEProvider>
    </ApolloProvider>
  );
}
